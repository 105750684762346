/* eslint-disable */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Action": [
      "ApiCallAction",
      "ApiCallGraphqlAction",
      "CalculationAction",
      "ChecklistAction",
      "ClinicalNoteAction",
      "ExtensionAction",
      "FormAction",
      "MessageAction",
      "PushToEmrAction"
    ],
    "ExtensionActionField": [
      "BooleanField",
      "DateField",
      "HtmlField",
      "JsonField",
      "NumericArrayField",
      "NumericField",
      "StringArrayField",
      "StringField",
      "TextField"
    ],
    "PaginationAndSortingPayload": [
      "ActivitiesPayload"
    ],
    "Payload": [
      "ActionPayload",
      "ActivateTriggerPayload",
      "AddActionToStepPayload",
      "AddChecklistToStepPayload",
      "AddConditionToRulePayload",
      "AddConstantPayload",
      "AddDataPointDefinitionToPathwayPayload",
      "AddDataPointMappingToExtensionActionPayload",
      "AddDecisionToTrackPayload",
      "AddDerivedDataPointDefinitionPayload",
      "AddLabelToStepPayload",
      "AddLocationToPathwayPayload",
      "AddMappingToApiCallPayload",
      "AddMessageToStepPayload",
      "AddQuestionToFormPayload",
      "AddQuestionsToFormPayload",
      "AddRuleToQuestionPayload",
      "AddStepFromLibraryToTrackPayload",
      "AddStepToLibraryPayload",
      "AddStepToTrackPayload",
      "AddStickyNoteToTrackPayload",
      "AddTrackToPathwayPayload",
      "AddTransformationToDynamicVariablePayload",
      "AddTriggerToPathwayPayload",
      "AddTriggerToTrackPayload",
      "AddWebhookPayload",
      "AhpLinkPayload",
      "AhpLinkPayloads",
      "BaselineInfoPayload",
      "CalculationResultPayload",
      "CalculationsPayload",
      "CareflowComponentsPayload",
      "ClearActionIdleTimePayload",
      "ClearTimingDelayPayload",
      "ClearTimingReferencePayload",
      "ClinicalNotePayload",
      "ConfigureExtensionPayload",
      "CreateTransitionPayload",
      "ConnectStepsPayload",
      "ConstantPayload",
      "ConstantsPayload",
      "CreateDynamicVariablePayload",
      "CreateFormPayload",
      "CreateMessagePayload",
      "CreatePathwayCasePayload",
      "CreatePathwayPayload",
      "DataCatalogJsonPayload",
      "DataCatalogPayload",
      "DataPointDefinitionPayload",
      "DataPointDefinitionsPayload",
      "DecisionPayload",
      "DecisionsPayload",
      "DeletePathwayCasePayload",
      "DomainEventsPayload",
      "DuplicateCareflowPayload",
      "DuplicateQuestionPayload",
      "DuplicateStepPayload",
      "DynamicVariablePayload",
      "DynamicVariablesPayload",
      "ElementsPayload",
      "EmptyPayload",
      "EmrReportPayload",
      "EndTrackAfterStepPayload",
      "EvaluatedRulePayload",
      "ExecuteTestApiCallPayload",
      "ExtensionActivityRecordPayload",
      "ExtensionConfigurationsPayload",
      "ExtensionsPayload",
      "FormPayload",
      "FormResponsePayload",
      "FormsPayload",
      "GeneratedClinicalNotePayload",
      "GeneratedEmrReportPayload",
      "GeneratedMessagePayload",
      "JsonPathPayload",
      "LocationsPayload",
      "MarkReleaseAsLivePayload",
      "MessagePayload",
      "MockObjectPayload",
      "OntologyNodePayload",
      "OntologyPayload",
      "PathwayCaseApiCallPayload",
      "PathwayCaseApiCallsPayload",
      "PathwayCasePayload",
      "PathwayCaseWebhookCallPayload",
      "PathwayCaseWebhookCallsPayload",
      "PathwayCasesPayload",
      "PathwayDiffPayload",
      "PathwayPayload",
      "PathwaySettingsPayload",
      "PathwayValidationStatusPayload",
      "PathwayVersionPayload",
      "PathwayVersionsPayload",
      "PathwaysPayload",
      "PublishPathwayPayload",
      "ReadMessagePayload",
      "RebuildViewModelsPayload",
      "RefreshExtensionPayload",
      "RemoveActionFromStepPayload",
      "RemoveClinicalNoteNarrativePayload",
      "RemoveConditionFromRulePayload",
      "RemoveConstantPayload",
      "RemoveDataPointMappingFromExtensionActionPayload",
      "RemoveLabelFromStepPayload",
      "RemoveMappingFromApiCallPayload",
      "RemoveQuestionFromFormPayload",
      "RemoveRuleFromQuestionPayload",
      "RemoveStepFromTrackPayload",
      "RemoveTrackFromPathwayPayload",
      "RemoveTransformationsFromDynamicVariablePayload",
      "RemoveTransitionPayload",
      "RemoveWebhookPayload",
      "ReorderActionsPayload",
      "ReorderQuestionsPayload",
      "RepairPathwaysPayload",
      "ResetPreviewPayload",
      "RetryWebhookCallPayload",
      "RulePayload",
      "SchemaPayload",
      "SetActionCalculationIdPayload",
      "SetActionCalculationInputPayload",
      "SetActionChecklistItemsPayload",
      "SetActionFormDisplayModePayload",
      "SetActionFormIdPayload",
      "SetActionIdleTimePayload",
      "SetActionMessageIdPayload",
      "SetActionStakeholdersPayload",
      "SetActionTitlePayload",
      "SetApiCallBodyPayload",
      "SetApiCallEndpointPayload",
      "SetApiCallHeadersPayload",
      "SetApiCallMethodPayload",
      "SetBrandingAccentColorPayload",
      "SetBrandingCustomThemePayload",
      "SetBrandingHostedPageAutoProgressPayload",
      "SetBrandingHostedPageAutosavePayload",
      "SetBrandingHostedPageTitlePayload",
      "SetBrandingLogoUrlPayload",
      "SetConditionOperandPayload",
      "SetConditionOperatorPayload",
      "SetConditionReferencePayload",
      "SetCustomActionFieldPayload",
      "SetDataPointDefinitionMetaDataFieldPayload",
      "SetDataPointDefinitionOptionalFieldPayload",
      "SetDataPointDefinitionPiiFieldPayload",
      "SetDateQuestionConfigPayload",
      "SetDynamicVariableDataPointDefinitionPayload",
      "SetDynamicVariableDataPointPropertyPayload",
      "SetDynamicVariableFallbackPayload",
      "SetDynamicVariableLabelPayload",
      "SetExtensionActionDataPointMappingDataPointPayload",
      "SetExtensionActionDataPointMappingKeyPayload",
      "SetExtensionActionDataPointMappingsPayload",
      "SetExtensionActionFieldPayload",
      "SetFormKeyPayload",
      "SetFormMetadataPayload",
      "SetFormTitlePayload",
      "SetFormTrademarkPayload",
      "SetMultipleSelectQuestionConfigPayload",
      "SetNumberQuestionConfigPayload",
      "SetPathwayCaseTitlePayload",
      "SetPathwayTitlePayload",
      "SetPhoneQuestionConfigPayload",
      "SetQuestionConfigPayload",
      "SetQuestionKeyPayload",
      "SetQuestionMetadataPayload",
      "SetQuestionOptionValueTypePayload",
      "SetQuestionsConfigPayload",
      "SetRemindersAmountPayload",
      "SetRemindersDelayPayload",
      "SetRuleBooleanOperatorPayload",
      "SetRuleTypePayload",
      "SetSliderQuestionConfigPayload",
      "SetStakeholderEmailsPayload",
      "SetStepCoordinatesPayload",
      "SetStepDocumentationPayload",
      "SetStepTitlePayload",
      "SetStickyNoteCoordinatesPayload",
      "SetTimingDelayPayload",
      "SetTimingReferencePayload",
      "SetTrackEndCoordinatesPayload",
      "SetTrackStartCoordinatesPayload",
      "SetTrackTitlePayload",
      "SetTransformationParamsPayload",
      "SetTransformationTypePayload",
      "SetTransitionDestinationPayload",
      "StakeholdersPayload",
      "StartPreviewPayload",
      "StartTrackFromStepPayload",
      "StartTransitionFromStepPayload",
      "StepLibraryPayload",
      "StepPayload",
      "StepsPayload",
      "StickyNotePayload",
      "StickyNotesPayload",
      "SubmitChecklistPayload",
      "SubmitFormResponsePayload",
      "TenantPayload",
      "TenantsPayload",
      "TestSourceControlPayload",
      "TimingPayload",
      "TrackPayload",
      "TracksPayload",
      "TransitionPayload",
      "TransitionsPayload",
      "TriggerActivationPayload",
      "TriggerActivationsPayload",
      "TriggerApiCallPayload",
      "TriggerPayload",
      "UpdateApiCallMappingDataPointPayload",
      "UpdateApiCallMappingFirstMatchOnlyPayload",
      "UpdateApiCallMappingKeyPayload",
      "UpdateCareFlowApiCallRetrySettingsPayload",
      "UpdateCareFlowSourceControlSettingsPayload",
      "UpdateClinicalNoteContextPayload",
      "UpdateConstantPayload",
      "UpdateDataPointDefinitionPayload",
      "UpdateDynamicVariablePayload",
      "UpdateEmailNotificationStatusPayload",
      "UpdateLabelTextPayload",
      "UpdateQuestionPayload",
      "UpdateStickyNoteBodyPayload",
      "UpdateWebhookEndpointPayload",
      "UpdateWebhookHeadersPayload",
      "UpdateWebhookNamePayload",
      "UpdateWebhookPayload",
      "UpdateWebhookStatusPayload",
      "UpdateWebhookSubscribedEventsPayload",
      "UpdateWebhookTestEndpointPayload",
      "UserPayload",
      "WebhookPayload"
    ],
    "Transformation": [
      "AddDurationTransformation",
      "AddPrefixTransformation",
      "AddSuffixTransformation",
      "AddWeekdaysTransformation",
      "FormatDateTransformation",
      "FormatPhoneNumberTransformation",
      "RoundToTransformation",
      "SubtractDurationTransformation"
    ],
    "TriggerActivationReference": [
      "AdHocActivationReference",
      "DataPointCollectedActivationReference",
      "PathwayStartedActivationReference",
      "StepCompletedActivationReference",
      "TrackCompletedActivationReference",
      "TrackStartedActivationReference"
    ],
    "TriggerSettings": [
      "AdHocTriggerSettings",
      "DataPointCollectedTriggerSettings",
      "DataPointNotCollectedTriggerSettings",
      "DesignatedTriggerTimer",
      "ExtensionWebhookTriggerSettings",
      "PathwayStartedTriggerSettings",
      "StepCompletedTriggerSettings",
      "TrackCompletedTriggerSettings"
    ]
  }
};
      export default result;
    